<template>
  <!-- Footer Start -->

  <footer class="section-t-space footer-section-2 footer-color-2">
    <div class="container-fluid-lg">
      <div class="main-footer">
        <div class="row g-md-4 gy-sm-5">
          <div class="col-xxl-3 col-xl-4 col-sm-6">
            <router-link :to="{ name: 'Home' }" class="foot-logo theme-logo">
              <img src="../../../assets/images/logo/4.png" class="img-fluid blur-up lazyload" alt="" />
            </router-link>
            <p class="information-text information-text-2">Welcome to Kingdom of Grain, your one-stop-shop for fresh flour and other high-quality grains. </p>
            <ul class="social-icon">
              <li class="light-bg">
                <a href="https://www.facebook.com/profile.php?id=100092266004172" target="_blank" class="footer-link-color">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li class="light-bg">
                <a href="https://www.instagram.com/kingdomofgrains/?igshid=MzNlNGNkZWQ4Mg==" target="_blank" class="footer-link-color">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="col-xxl-2 col-xl-4 col-sm-6">
            <div class="footer-title">
              <h4 class="text-white">Kingdom of Grains</h4>
            </div>
            <ul class="footer-list footer-contact footer-list-light">
              <li>
                <router-link :to="{ name: 'AboutUs' }" class="light-text">About Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'TermsCondition' }" class="light-text">Terms & Coditions</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'PrivacyPolicy' }" class="light-text">Privacy Policy</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ContactUs' }" class="light-text">Contact Us</router-link>
              </li>
              <!-- <li>
                <router-link :to="{ name: 'Faq' }" class="light-text">FAQs</router-link>
              </li> -->
            </ul>
          </div>

          <div class="col-xxl-2 col-xl-4 col-sm-6">
            <div class="footer-title">
              <h4 class="text-white">Useful Link</h4>
            </div>
            <ul class="footer-list footer-list-light footer-contact"> 
              <li>
                <router-link :to="{ name: 'OrderList' }" class="light-text">Your Order</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'MyProfile' }" class="light-text">Your Account</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'OrderTracking' }" class="light-text">Track Orders</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'WishList' }" class="light-text">Your Wishlist</router-link>
              </li>              
            </ul>
          </div>

          <div class="col-xxl-2 col-xl-4 col-sm-6">
            <div class="footer-title">
              <h4 class="text-white">Categories</h4>
            </div>
            <ul class="footer-list footer-list-light footer-contact">
              <li v-for="(categoryData,index) in categoryListData" :key="index">
                <router-link :to="{ name: 'ShopByCategory',params:{categorySlug: categoryData.slug}}" class="light-text">
                    {{categoryData.name}}   
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-xxl-3 col-xl-4 col-sm-6">
            <div class="footer-title">
              <h4 class="text-white">Store infomation</h4>
            </div>
            <ul class="footer-address footer-contact">
              <li>
                <a href="javascript:void(0)" class="light-text">
                  <div class="inform-box flex-start-box">
                    <i data-feather="map-pin"></i>
                    <p>Sawan Naturals Malerna Road Faridabad, Haryana </p>
                  </div>
                </a>
              </li>

              <li>
                <a href="javascript:void(0)" class="light-text">
                  <div class="inform-box">
                    <i data-feather="phone"></i>
                    <p>Call us: +91-7018822209</p>
                  </div>
                </a>
              </li>

              <li>
                <a href="javascript:void(0)" class="light-text">
                  <div class="inform-box">
                    <i data-feather="mail"></i>
                    <p>care@kingdomofgrains.com</p>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div class="sub-footer sub-footer-lite section-b-space section-t-space">
        <div class="left-footer">
          <p class="light-text">2023 Copyright By Kingdom of Grains</p>
        </div>

        <ul class="payment-box">
          <li>
            <img src="../../../assets/images/icon/paymant/visa.png" class="blur-up lazyload" alt="" />
          </li>
          <li>
            <img src="../../../assets/images/icon/paymant/discover.png" alt="" class="blur-up lazyload" />
          </li>
          <li>
            <img src="../../../assets/images/icon/paymant/american.png" alt="" class="blur-up lazyload" />
          </li>
          <li>
            <img src="../../../assets/images/icon/paymant/master-card.png" alt="" class="blur-up lazyload" />
          </li>
          <li>
            <img src="../../../assets/images/icon/paymant/giro-pay.png" alt="" class="blur-up lazyload" />
          </li>
        </ul>
      </div>
    </div>
    
  </footer>
  <!-- Footer End -->

  <!-- Tap to top start -->
  <div class="theme-option">
    <div class="back-to-top">
      <a id="back-to-top" href="#">
        <i class="fas fa-chevron-up"></i>
      </a>
    </div>
  </div>
  <!-- Tap to top end -->

  <!-- Bg overlay Start -->
  <div class="bg-overlay"></div>
</template>

<script>
/*import "../../../assets/js/jquery-ui.min.js"; */
// no import "../../../assets/js/bootstrap/bootstrap.bundle.min.js";
/*import "../../../assets/js/bootstrap/bootstrap-notify.min.js";
import "../../../assets/js/bootstrap/popper.min.js";*/
// no import "../../../assets/js/feather/feather.min.js";
// no import "../../../assets/js/feather/feather-icon.js";
/*import "../../../assets/js/lazysizes.min.js";
import "../../../assets/js/slick/slick.js";
import "../../../assets/js/slick/slick-animation.min.js";
import "../../../assets/js/custom-slick-animated.js";
import "../../../assets/js/slick/custom_slick.js";
import "../../../assets/js/ion.rangeSlider.min.js";
import "../../../assets/js/auto-height.js";
import "../../../assets/js/quantity-2.js";
import "../../../assets/js/fly-cart.js";*/
// import "../../../assets/js/timer1.js";
// import "../../../assets/js/timer2.js";
// import "../../../assets/js/clipboard.min.js";
// import "../../../assets/js/copy-clipboard.js";
// import "../../../assets/js/wow.min.js";
// import "../../../assets/js/custom-wow.js";
// import "../../../assets/js/script.js";

/* Import all file*/

import "../../../assets/js/jquery-ui.min.js"; 
// import "../../../assets/js/bootstrap/bootstrap-notify.min.js";
import "../../../assets/js/bootstrap/popper.min.js";
import "../../../assets/js/lazysizes.min.js";
import "../../../assets/js/ion.rangeSlider.min.js";
// import "../../../assets/js/auto-height.js";
// import "../../../assets/js/quantity-2.js";
// import "../../../assets/js/fly-cart.js";
import "../../../assets/js/script.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      categoryListData: {},
    };
  },
  beforeMount() {
      this.getcategoryList();
  },
  methods: {
    async getcategoryList() {
      let vueInstance = this;
      vueInstance.checkTokenLoggedIn= this.$store.state.auth.authData.token;
      await vueInstance.actionGetCategories().then((res) => {
          if (res.error == true) {
              return false;
          }
          vueInstance.categoryListData = res.response;
          //setTimeout(() => {
              // vueInstance.totalCategory = vueInstance.categoryListData.length
          // }, 2000);
      })
    },
    ...mapActions("customer", {
      actionGetCategories: "getCategories",
    }),
  },
};
</script>
<style scope>
.whatsapp_float {
	position:fixed;
	bottom: 40px;
	right: 20px;
}
</style>
