<template>
    <header-component/>
    <!-- Home Section Start -->
    <section class="home-section-2 home-section-bg pt-0 overflow-hidden">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <swiper class="swiper-slide-banner"
                        :slidesPerView="1"
                        :centeredSlides="true"
                        :autoplay="{
                            delay: 4500,
                        }"
                        :modules="modules"
                        :effect="'fade'"
                        :lazy="true"
                    >
                        <swiper-slide v-for="(banner,index) in bannerData" :key="index" class="home-contain rounded-0 p-0">
                                <img :src="banner.image" class="img-fluid bg-img blur-up lazyload" alt="">
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
    <!-- Home Section End -->

    <!-- Fresh Vegetable Section Start -->
    <section class="fresh-vegetable-section section-lg-space">
        <div class="container-fluid-lg">
            <div class="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
                <div class="col-xl-12 col-12">
                  <div class="about-us-title text-center">
                        <h2 class="center">KOG Model Of Excellence</h2>
                    </div>
                    <div class="text-center">
                        <img src="../../../assets/images/logo/process.png" class="img-fluid" width="800" alt="">
                    </div>
                    
                   </div> 


            </div>
        </div>
    </section>
    <!-- Fresh Vegetable Section End -->

    <!-- What We Do Section Start -->
    <section class="client-section section-lg-space wsection">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="about-us-title text-center">
                        <!-- <h4>What We Do</h4> -->
                        <h2 class="center">A Recipe For Perfection Just Choose Your Own</h2>
                    </div>

                    <!-- <div class="slider-3_1 product-wrapper"> -->
                    <swiper class="swiper-slide-banner swiper-wrappe" 
                        :breakpoints="{
                            0: {       
                                slidesPerView: 1,    
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                        }"
                        :modules="modules"
                        :freeMode="true"
                        :spaceBetween="20"
                        :autoplay="{
                            delay: 4500,
                        }"
                        
                    >
                        <swiper-slide>
                            <div class="clint-contain">
                                <div class="client-icon">
                                    <img src="../../../assets/images/wheat/wheat.png" class="blur-up lazyload wimg" alt="">
                                </div>
                                <h4>Select Wheat</h4>
                                <p>As a professional group. We are dedicated to supplying the greatest wheat variety to our valued customers.</p>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="clint-contain">
                                <div class="client-icon">
                                    <img src="../../../assets/images/wheat/wheat-pisai.jpg" class="blur-up lazyload wimg" alt="">
                                </div>
                                <h4>Choose Pisai</h4>
                                <p>Our team of specialists is available to offer advice to assist you in determining the appropriate Pisai approach for your needs.</p>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="clint-contain">
                                <div class="client-icon">
                                    <img src="../../../assets/images/wheat/bag.png" class="blur-up lazyload wimg" alt="">
                                </div>
                                <h4>Select Size</h4>
                                <p>Kingdom of Grains provides a variety of sizes from which you can choose based on your requirements.</p>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </section>
    <!-- What We Do Section End -->

    <!-- Category Section Start 
    <section class="category-section-3">
        <div class="container-fluid-lg">
            <div class="title">
                <h2>Shop By Categories</h2>
            </div>
            <div class="row">
                <div class="col-12">
                    <swiper class="wow fadeInUp swiper-slide-cateory"
                        :slidesPerView="3"
                        :spaceBetween="40"
                        :modules="modules"
                        :lazy="true"
                    >
                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Regular Whole-Wheat Atta</h4>
                                    <h6>25 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/1.jpg"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Regular Whole-Wheat Atta</h4>
                                    <h6>20 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/2.png"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Sharbati Whole-Wheat Atta</h4>
                                    <h6>14 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/3.jpg"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Sharbati Whole-Wheat Atta</h4>
                                    <h6>43 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/16.png"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Sharbati Whole-Wheat Atta</h4>
                                    <h6>23 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/5.jpg"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Sharbati Whole-Wheat Atta</h4>
                                    <h6>54 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/8.png"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Regular Whole-Wheat Atta</h4>
                                    <h6>29 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/8.png"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = 'shop-category-slider.php';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="category-box-list">
                                <a href="shop-category-slider.php" class="category-name">
                                    <h4>MP Sharbati Whole-Wheat Atta</h4>
                                    <h6>25 items</h6>
                                </a>
                                <div class="category-box-view">
                                    <a href="shop-category-slider.php">
                                        <img src="../../../assets/images/grocery/category/9.jpg"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </a>
                                    <button onclick="location.href = '#';" class="btn shop-button">
                                        <span>Shop Now</span>
                                        <i class="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
    Category Section End -->

    <!-- Top Selling Items Section Start -->
    <section class="product-section-3">
        <div class="container-fluid-lg">
            <div class="title">
                <h2>Top Selling Items</h2>
            </div>
            <div class="row">
                <div class="col-12">
                    <swiper class="mg-slider" :navigation="true"
                        :breakpoints="swiperBreakpoints"
                        :spaceBetween="20"
                        :modules="modules"
                        :lazy="true"
                        :autoplay="{
                            delay: 4500,
                        }"
                    >
                    <swiper-slide v-for="(topSelling,index) in topSellingData" :key="index" :class="`product-box-4 wow fadeInUp ${index==0?'mt-3':''}` ">
                        
                        <div class="product-image product-image-2" >
                            <router-link :to="{ name: 'ProductDetails',params:{productSlug: topSelling.slug}}">
                            <img :src="topSelling.images[0].image_url" class="img-fluid blur-up lazyload" alt="" />
                            </router-link>

                            <ul class="option">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Quick View">
                                <a href="javascript:void(0)" @click.prevent="viewProduct(topSelling)">
                                <i class="iconly-Show icli"></i>
                                </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                                <a href="javascript:void(0)"  @click="addToWish(topSelling.id,topSelling.variant.id)" class="notifi-wishlist">
                                <i class="iconly-Heart icli"></i>
                                </a>
                            </li>
                            </ul>
                        </div>

                        <div class="product-detail">
                            <ul class="rating">
                                <star-rating :max="5" :current="topSelling.rating" />
                            </ul>
                            <router-link :to="{ name: 'ProductDetails',params:{productSlug: topSelling.slug}}">
                            <h5 class="name text-title">{{ topSelling.name }}</h5>
                            </router-link>
                            <h5 class="price theme-color">₹{{ topSelling.variant.sale_price }}<del>₹{{ topSelling.variant.retail_price }}</del></h5>
                        </div>
                    </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>

     <!-- Offer Section Start -->
    <!-- <section class="offer-section">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="offer-box" >
                        
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="offer-section">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div>
                        <img width="100%" src="../../../assets/images/grocery/banner/10.png">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Offer Section End -->

    <div v-for="(categoryProduct,index) in categoryProductsList" :key="index">
    <!-- Product Breakfast & Dairy Section Start -->
    
        <!-- <section class="product-section-3">
            <div class="container-fluid-lg">
                <div class="title">
                    <h2>{{ categoryProduct.title }}</h2>
                </div>
                <div class="row">
                    <div class="col-12">
                        <swiper class="mg-slider"
                            :slidesPerView="2"
                            :spaceBetween="20"
                            :modules="modules"
                            :lazy="true"
                        >
                            <swiper-slide v-for="(productDetail,jIndex) in categoryProduct.products" :key="jIndex" class="product-box-4 fadeInUp">
                                <SellingIteamSlider />
                            </swiper-slide>

                        </swiper>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- Product Breakfast & Dairy Section Start -->
     <section class="product-section">
        <div class="container-fluid-lg">
            <div class="title">
                <h2>
                    <router-link :to="{ name: 'ShopByCategory',params:{categorySlug: categoryProduct.slug}}">
                        {{ categoryProduct.name }}
                    </router-link>
            </h2>
            </div>
            <div class="row">
                <div class="col-12" v-if="categoryProduct.products.length > 0">
                    <swiper class="mg-slider" :navigation="true"
                        :breakpoints="swiperBreakpoints"
                        :spaceBetween="20"
                        :modules="modules"
                        :lazy="true"
                        :autoplay="{
                            delay: 4500,
                        }"
                    >
                        <swiper-slide v-for="(productDetail,jIndex) in categoryProduct.products" :key="jIndex">
                            <div class="product-box-4 wow fadeInUp">
                                <div class="product-image product-image-2">
                                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: productDetail.slug}}">
                                        <img :src="productDetail.images[0].image_url"
                                            class="img-fluid blur-up lazyload" alt="">
                                    </router-link>

                                    <ul class="option">
                                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Quick View">
                                            <a href="javascript:void(0)" @click.prevent="viewCategoryProduct(productDetail)">
                                                <i class="iconly-Show icli"></i>
                                            </a>
                                        </li>
                                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                                            <a href="javascript:void(0)" @click="addToWish(productDetail.id,productDetail.variants[0].id)" class="notifi-wishlist">
                                                <i class="iconly-Heart icli"></i>
                                            </a>
                                        </li>                                       
                                    </ul>
                                </div>

                                <div class="product-detail">
                                    <ul class="rating">
                                        <star-rating :max="5" :current="productDetail.rating" />
                                    </ul>
                                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: productDetail.slug}}">
                                        <h5 class="name text-title">{{ productDetail.name }}</h5>
                                    </router-link>
                                    <h5 class="price theme-color">₹{{ productDetail.variants[0].sale_price }}<del>₹{{ productDetail.variants[0].retail_price }}</del></h5>
                                    <!-- <div class="addtocart_btn">
                                        <button class="add-button addcart-button btn buy-button text-light">
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                        <div class="qty-box cart_qty">
                                            <div class="input-group">
                                                <button type="button" class="btn qty-left-minus" data-type="minus"
                                                    data-field="">
                                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                                <input class="form-control input-number qty-input" type="text"
                                                    name="quantity" value="1">
                                                <button type="button" class="btn qty-right-plus" data-type="plus"
                                                    data-field="">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </swiper-slide>

                    </swiper>
                </div>
                <div class="col-12" v-else><h5>Comming Soon</h5></div>
            </div>
        </div>
    </section>
    <!-- Product Breakfast & Dairy Section End --> 

    <!-- Product Breakfast & Dairy Section End --> 

        <section class="six-box-section section-lg-space" v-if="index ==0">
            <div class="container-fluid-lg">
                <div class="row">
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>100% Freshly Milled</h4>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>Zero Preservatives</h4>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>Stone Milling</h4>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>High in Vitamins and Fiber</h4>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>Organic Grains</h4>
                    </div>
                    <div class="col-2">
                        <img src="../../../assets/images/232.png" class="img-fluid n-img" />
                <h4>Freshly Milled</h4>
                    </div>
                </div>
            </div>
        </section>

    

        <!-- Banner Section Start -->
        <section class="banner-section" v-if="index ==1">
            <div class="container-fluid-lg">
                <div class="row gy-lg-0 gy-3">
                    <div class="col-lg-8">
                        <div class="banner-contain-3 h-100 pt-sm-5 hover-effect bg-size blur-up lazyloaded" style="background-image: url(&quot;../assets/images/grocery/banner/8.png&quot;); background-size: cover; background-position: center center; background-repeat: no-repeat; display: block;">
                            <img src="../../../assets/images/grocery/banner/8.png" class="bg-img blur-up lazyload" alt="" style="display: none;">
                            <div class="banner-detail banner-p-sm p-center-right position-relative banner-minus-position banner-detail-deliver">
                                <div>
                                    <h3 class="fw-bold banner-contain">Safe Delivery to the door</h3>
                                    <!-- <h4 class="mb-sm-3 mb-2 delivery-contain">Make money on your terms. Anytime and anyhow.</h4> -->
                                    <ul class="banner-list">
                                        <li>
                                            <div class="delivery-box">
                                                <div class="check-icon">
                                                    <i class="fa-solid fa-check"></i>
                                                </div>

                                                <div class="check-contain">
                                                    <h5>Get live order tracking</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div class="delivery-box">
                                                <div class="check-icon">
                                                    <i class="fa-solid fa-check"></i>
                                                </div>

                                                <div class="check-contain">
                                                    <h5>Get latest feature updates</h5>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="banner-contain-3 pt-lg-4 h-100 hover-effect">
                            <a href="javascript:void(0)">
                                <img src="../../../assets/images/grocery/banner/9.jpg"
                                    class="img-fluid social-image blur-up lazyload w-100" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Banner Section End -->

    </div>

    <!-- Review Section Start -->
    <section class="review-section section-lg-space">
        <div class="container-fluid">
            <div class="about-us-title text-center">
                <h2 class="center">Voice Of Customers</h2>
            </div>
            <div class="row">
                <div class="col-12">
                    <swiper
                        class="product-wrapper"
                        :style="{
                            '--swiper-pagination-bullet-active': 'saddlebrown',
                            '--swiper-pagination-color': 'saddlebrown',
                        }"
                        :breakpoints="swiperBreakpoints"
                        :spaceBetween="20"
                        :modules="modules"
                        :lazy="true"
                        :pagination="{
                            dynamicBullets: true,
                        }"
                        :autoplay="{
                            delay: 4500,
                        }"
                    >
                        <swiper-slide v-for="(customerReviews,index) in customerReviewsData" :key="index">
                            <TestimonalSlider :customerReview=customerReviews />
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
    <!-- Review Section End -->

     <!-- Service Section Start -->
     <section class="service-section section-b-space">
        <div class="container-fluid-lg">
            <div class="row g-3 row-cols-xxl-5 row-cols-lg-3 row-cols-md-2">
                <div>
                    <div class="service-contain-2">
                        <svg class="icon-width">
                            <use xlink:href="../../../assets/svg/svg/service-icon-4.svg#shipping"></use>
                        </svg>
                        <div class="service-detail">
                            <h3>Free Shipping</h3>
                            <h6 class="text-content">Free Shipping world wide</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="service-contain-2">
                        <svg class="icon-width">
                            <use xlink:href="../../../assets/svg/svg/service-icon-4.svg#service"></use>
                        </svg>
                        <div class="service-detail">
                            <h3>24 x 7 Service</h3>
                            <h6 class="text-content">Online Service For 24 x 7</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="service-contain-2">
                        <svg class="icon-width">
                            <use xlink:href="../../../assets/svg/svg/service-icon-4.svg#pay"></use>
                        </svg>
                        <div class="service-detail">
                            <h3>Online Pay</h3>
                            <h6 class="text-content">Online Payment Avaible</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="service-contain-2">
                        <svg class="icon-width">
                            <use xlink:href="../../../assets/svg/svg/service-icon-4.svg#offer"></use>
                        </svg>
                        <div class="service-detail">
                            <h3>Festival Offer</h3>
                            <h6 class="text-content">Super Sale Upto 50% off</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="service-contain-2">
                        <svg class="icon-width">
                            <use xlink:href="../../../assets/svg/svg/service-icon-4.svg#return"></use>
                        </svg>
                        <div class="service-detail">
                            <h3>100% Original</h3>
                            <h6 class="text-content">100% Money Back</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Service Section End -->

    <!-- Quick View Modal Box Start -->
    <Teleport to="body">
        <div class="modal theme-modal view-modal" id="viewProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header p-0">
                <button type="button" class="btn-close" @click="closeModel">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                </div>
                <div class="modal-body">
                <div class="row g-sm-4 g-2">
                    <div class="col-lg-6">
                    <div class="slider-image">
                        <img :src="productViewimage" class="img-fluid blur-up lazyload" alt="" />
                    </div>
                    </div>

                    <div class="col-lg-6">
                    <div class="right-sidebar-modal">
                        <h4 class="title-name">{{ productViewName }}</h4>
                        <h4 class="price">₹{{ productViewSalePrice }} <del>₹{{ productViewRetailPrice }}</del></h4>
                        <div class="product-rating">
                        <ul class="rating">
                            <star-rating :max="5" :current="productViewRating" />
                        </ul>
                        <span class="ms-2">{{ productViewReviewCount }} Reviews</span>
                        <!-- <span class="ms-2 text-danger">6 sold in last 16 hours</span> -->
                        </div>

                        <div class="product-detail">
                        <h4>Product Details :</h4>
                        <p>{{ productViewDetail }}</p>
                        </div>

                        <ul class="brand-list">
                            <li>
                                <div class="brand-box">
                                <h5>Weight:</h5>
                                <h6>{{ productViewWeight }}</h6>
                                </div>
                            </li>
                        </ul>

                        <div class="modal-button" v-if="productViewSlug">
                            <router-link :to="{ name: 'ProductDetails',params:{productSlug: productViewSlug}}" class="btn theme-bg-color view-button icon text-white fw-bold btn-md">View More Details</router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </Teleport>
    <!-- Quick View Modal Box End --> 
</template>

<script>
// import slick from "../../../assets/js/slick/slick.js";
// import slickAnimation from "../../../assets/js/slick/slick-animation.min.js";
// import customSlickAnimated from "../../../assets/js/custom-slick-animated.js";
// import customSlick from "../../../assets/js/slick/custom_slick.js";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade, FreeMode, Pagination, Navigation } from 'swiper';
import 'swiper/css'
import "swiper/css/effect-fade";
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { mapActions } from "vuex";
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
import TestimonalSlider from '../../../components/forntEnd/TestimonalSlider' 
import starRating from "../../../components/RatingComponent";
import { toast } from 'vue3-toastify';
  
export default {
    components: { Swiper, SwiperSlide, TestimonalSlider, starRating, HeaderComponent },
    data() {
        return {
            swiperBreakpoints:{
                320: {       
                    slidesPerView: 2,
                    spaceBetween: 10     
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 4,
                },
            },
            modules: [Navigation, Autoplay, EffectFade, FreeMode, Pagination],
            homeListData: {},
            bannerData:{},
            categoryProductsList:{},
            customerReviewsData:{},
            topSellingData:{},
            checkTokenLoggedIn: false,

            productViewimage: '',
            productViewName: '',
            productViewSalePrice: 0,
            productViewRetailPrice: 0,
            productViewReviewCount: 0,
            productViewDetail: '',
            productViewRating: 0,
            productViewWeight: '',
            productViewSlug: ''

        };
    },
    beforeMount() {
        this.getHomeDataList();       
        this.getCategoryProductsList();     
    },
    methods: {
        async getHomeDataList() {
            let vueInstance = this;
            vueInstance.checkTokenLoggedIn= this.$store.state.auth.authData.token;
            await vueInstance.actionGetHomeDataList().then((res) => {
                if (res.error == true) {
                    return false;
                }
                vueInstance.homeListData = res.response;
                vueInstance.bannerData = vueInstance.homeListData.banners.TOP_BANNER;
                vueInstance.topSellingData = vueInstance.homeListData.top_selling;
                vueInstance.customerReviewsData = vueInstance.homeListData.reviews;
            })
        },

        async getCategoryProductsList() {
            let vueInstance = this;
            vueInstance.checkTokenLoggedIn= this.$store.state.auth.authData.token;
            await vueInstance.actionGetCategoryProductsList().then((res) => {
                if (res.error == true) {
                    return false;
                }
                vueInstance.categoryProductsList = res.response;
            })
        },

        async addToWish(productId,variantId){
            let vueInstance = this;
            if(!vueInstance.checkTokenLoggedIn){
                toast.error("You are not logged in. Please login to add the product to your the card");
                setTimeout(function(){
                    vueInstance.$router.push({ name: "Login" });
                }, 5000);
                return false
            }            
            await vueInstance.actionAddToWish({
                variant_id: variantId,
                product_id: productId,
                wish_type: "ADD",
            }).then(results => {
                if (results.error == true) {
                    toast.error(results.message);
                    return false
                }
                toast.success(results.message);
                return false;
            }).catch((err) => {
                toast.error(err.message);
                return false;
            });
        },

        viewProduct(productViewDetail){ 
            let vueInstance = this;
            vueInstance.productViewimage = productViewDetail.images[0].image_url;
            vueInstance.productViewName = productViewDetail.name;
            vueInstance.productViewSlug = productViewDetail.slug;
            vueInstance.productViewSalePrice = productViewDetail.variant.sale_price;
            vueInstance.productViewRetailPrice = productViewDetail.variant.retail_price;
            vueInstance.productViewReviewCount = productViewDetail.reviews.length
            vueInstance.productViewDetail = productViewDetail.variant.description;
            vueInstance.productViewWeight = productViewDetail.variant.unit_value + productViewDetail.variant.unit;
            vueInstance.productViewRating = productViewDetail.rating;
            $('#viewProduct').show()
        },

        viewCategoryProduct(productViewDetail){ 
            let vueInstance = this;
            vueInstance.productViewimage = productViewDetail.images[0].image_url;
            vueInstance.productViewName = productViewDetail.name;
            vueInstance.productViewSlug = productViewDetail.slug;
            vueInstance.productViewSalePrice = productViewDetail.variants[0].sale_price;
            vueInstance.productViewRetailPrice = productViewDetail.variants[0].retail_price;
            vueInstance.productViewReviewCount = productViewDetail.reviews.length
            vueInstance.productViewDetail = productViewDetail.variants[0].description;
            vueInstance.productViewWeight = productViewDetail.variants[0].unit_value + productViewDetail.variants[0].unit;
            vueInstance.productViewRating = productViewDetail.rating;
            $('#viewProduct').show()
        },
        closeModel(){ 
            $('#viewProduct').hide()
        },
        ...mapActions("customer", {
            actionGetHomeDataList: "getHomeDataList",
        }),
        ...mapActions("customer", {
            actionGetCategoryProductsList: "getCategoryProductsList",
        }),
        ...mapActions("customer", {
            actionAddToWish: "addToWish",
        }),
    }
   
};
</script>

<style scoped>
.swiper-slide-banner img {
  display: block;
  width: 100%;
}
.swiper-slide-cateory img {
  display: block;
  width: 100%;
}

</style>